import React from "react";
import { useTranslation } from "react-i18next";
import { Fragrance, SheetData } from "../App";
import TextInput from "./TextInput";

const abbreviations: any = {
  it: "ITA",
  en: "ENG",
  fr: "FRA",
  du: "DEU",
  es: "ESP",
};

type Props = {
  formik: any;
  openModal(): void;
  fragrances: Fragrance[];
  sheetData: SheetData;
};

const Form: React.FC<Props> = ({
  formik,
  openModal,
  fragrances,
  sheetData,
}) => {
  const { t, i18n } = useTranslation("translation");
  return (
    <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
      <label className="label">
        <span>{t("fragrance")}</span>
        <select {...formik.getFieldProps("fragrance")}>
          <option value="" selected>
            {t("notSelected")}
          </option>
          {fragrances.map((fragrance, index) => (
            <option value={fragrance.name} key={`fragrance-${index}`}>
              {
                sheetData[fragrance.index][
                  "Name of Fragrance " + abbreviations[i18n.language]
                ]
              }
            </option>
          ))}
        </select>
      </label>
      <label className="label">
        <span>{t("product")}</span>
        <select {...formik.getFieldProps("quality")}>
          <option value="" selected>
            {t("notSelected")}
          </option>
          {sheetData
            .filter(
              (row) => row["Name of Fragrance"] === formik.values.fragrance
            )
            .map((row, index) => (
              <option key={`quality-${index}`} value={row["Product Q.ty %"]}>
                {row["Product Q.ty % " + abbreviations[i18n.language]]}
              </option>
            ))}
        </select>
      </label>
      <label className="label">
        <span>{t("language")}</span>
        <select {...formik.getFieldProps("language")}>
          {[
            {
              abbreviation: "ITA",
              flag: "🇮🇹",
              name: t("italian"),
            },
            {
              abbreviation: "ENG",
              flag: "🇬🇧",
              name: t("english"),
            },
            {
              abbreviation: "FRA",
              flag: "🇫🇷",
              name: t("french"),
            },
            {
              abbreviation: "DEU",
              flag: "🇩🇪",
              name: t("dutch"),
            },
            {
              abbreviation: "ESP",
              flag: "🇪🇸",
              name: t("spanish"),
            },
          ].map((language) => (
            <option key={language.abbreviation} value={language.abbreviation}>
              {language.name}
            </option>
          ))}
        </select>
      </label>
      <label className="label">
        <span>{t("mass")}</span>
        <input type="number" {...formik.getFieldProps("mass")} />
      </label>
      <div className="label">
        <span>{t("units")}</span>
        <div className="radio-options">
          <label>
            <input
              type="radio"
              checked={formik.values.unit === "g"}
              onChange={() => formik.setFieldValue("unit", "g")}
            />
            <span>{t("grams")}(g)</span>
          </label>
          <label>
            <input
              type="radio"
              checked={formik.values.unit === "ml"}
              onChange={() => formik.setFieldValue("unit", "ml")}
            />
            <span>{t("milliliters")}(ml)</span>
          </label>
        </div>
      </div>
      <div className="label">
        <span>{t("shape")}</span>
        <div className="radio-options">
          <label className="shape-option">
            <div className="shape-wrapper">
              <div className="circle"></div>
              <span>60mm</span>
            </div>
            <input
              name="shape"
              type="radio"
              checked={formik.values.shape === "circle"}
              onChange={() => formik.setFieldValue("shape", "circle")}
            />
          </label>
          <label className="shape-option">
            <div className="shape-wrapper">
              <div className="square"></div>
              <span>60mm</span>
            </div>
            <input
              name="shape"
              type="radio"
              checked={formik.values.shape === "square"}
              onChange={() => formik.setFieldValue("shape", "square")}
            />
          </label>
        </div>
      </div>
      <label className="label">
        <span>{t("background")}</span>
        <div>
          <input type="color" {...formik.getFieldProps("background")} />
        </div>
      </label>
      <div className="label">
        <span>{t("text")}</span>
        <div className="radio-options">
          <label>
            <input
              name="text-color"
              type="radio"
              checked={formik.values.textColor === "#000000"}
              onChange={() => formik.setFieldValue("textColor", "#000000")}
            />
            <span>{t("black")}</span>
          </label>
          <label>
            <input
              name="text-color"
              type="radio"
              checked={formik.values.textColor === "#ffffff"}
              onChange={() => formik.setFieldValue("textColor", "#ffffff")}
            />
            <span>{t("white")}</span>
          </label>
        </div>
      </div>
      <TextInput
        label={t("productName")}
        placeholder={t("productPlaceholder")}
        {...formik.getFieldProps("productName")}
      />
      <TextInput
        label={t("businessName")}
        placeholder={t("businessNamePlaceholder")}
        {...formik.getFieldProps("businessName")}
      />
      <TextInput
        label={t("businessAddress")}
        placeholder={t("addressPlaceholder")}
        {...formik.getFieldProps("businessAddress")}
      />
      <TextInput
        label={t("businessTelephone")}
        placeholder="0123456789"
        {...formik.getFieldProps("businessTelephone")}
      />
      <TextInput
        label={t("UFI")}
        placeholder="UFI:XXXX-XXXX-XXXX-XXXX"
        {...formik.getFieldProps("ufi")}
      />
      <TextInput
        label={t("batchNumber")}
        placeholder="BN:1123"
        {...formik.getFieldProps("batchNumber")}
      />
      <div className="label">
        <span>{t("EN15494")}</span>
        <div className="radio-options">
          {Array.from(Array(6)).map((_, index) => (
            <label key={`pictogram-${index}`} className="pictogram">
              <div
                className={`pictogram-image ${
                  formik.values.textColor === "#000000" ? "black" : "white"
                }`}
              >
                <img
                  src={`/pictogram/${index}_${
                    formik.values.textColor === "#000000" ? "black" : "white"
                  }.png`}
                  alt=""
                />
              </div>
              <input
                type="checkbox"
                checked={
                  formik.values.pictograms.find(
                    (el: number) => el === index
                  ) !== undefined
                }
                onChange={(event) => {
                  if (event.target.checked) {
                    if (formik.values.pictograms.length < 5) {
                      formik.setFieldValue("pictograms", [
                        ...formik.values.pictograms,
                        index,
                      ]);
                    }
                  } else {
                    formik.setFieldValue(
                      "pictograms",
                      formik.values.pictograms.filter(
                        (el: number) => el !== index
                      )
                    );
                  }
                }}
              />
            </label>
          ))}
        </div>
      </div>
      <div className="buttons">
        <input className="refresh" type="reset" value={t("refresh")!} />
        <button className="preview" type="button" onClick={openModal}>
          {t("preview")}
        </button>
      </div>
    </form>
  );
};

export default Form;
