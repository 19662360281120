import React from "react";

type Props = {
  label: string;
  [x: string]: any;
};

const TextInput: React.FC<Props> = ({ label, ...rest }) => {
  return (
    <label className="label">
      <span>{label}</span>
      <input {...rest} />
    </label>
  );
};

export default TextInput;
