// lib
import { useEffect, useState } from "react";
import themeSwitcher from "./lib/theme-switcher";
import Papa from "papaparse";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useTranslation } from "react-i18next";

// styles
import "@picocss/pico";
import "./global.css";

// components
import { useFormik } from "formik";
import Form from "./components/Form";
import PDFPreview, { Shape } from "./components/PDFPreview";
import { Arrow } from "./components/Arrow";
import LanguageSelect from "./components/LanguageSelect";

type FormValues = {
  fragrance: string;
  quality: string;
  language: string;
  mass: number;
  unit: "g" | "ml";
  shape: Shape;
  background: `#${string}`;
  textColor: "#000000" | "#ffffff";
  productName: string;
  businessName: string;
  businessAddress: string;
  businessTelephone: string;
  ufi: string;
  batchNumber: string;
  textOffset: number;
  addressOffset: number;
  fontSize: string;
  showBorder: boolean;
  pictograms: number[];
};

export type SheetData = Array<{
  "Name of Fragrance": string;
  "Product Q.ty %": string;
  GHS07: "YES" | "NO";
  GHS09: "YES" | "NO";
  GHS08: "YES" | "NO";
  GHS05: "YES" | "NO";
  [x: string]: string;
}>;

export type Fragrance = {
  name: string;
  index: number;
};

function App() {
  const [modalOpen, setModalOpen] = useState(false);
  const [fragrances, setFragrances] = useState<Fragrance[]>([]);
  const [sheetData, setSheetData] = useState<SheetData>([]);
  const { t } = useTranslation("translation");

  const initialValues: FormValues = {
    fragrance: "",
    quality: "",
    language: "ITA",
    mass: 220,
    unit: "g",
    shape: "circle",
    background: "#ffffff",
    textColor: "#000000",
    productName: "",
    businessName: "",
    businessAddress: "",
    businessTelephone: "",
    ufi: "",
    batchNumber: "",
    textOffset: 0,
    addressOffset: 0,
    fontSize: "10pt",
    showBorder: true,
    pictograms: [],
  };

  const formik = useFormik({
    initialValues,
    onSubmit: (values) => {
      console.log(values);
    },
  });

  useEffect(() => {
    themeSwitcher.init();
    Papa.parse(
      "https://docs.google.com/spreadsheets/d/e/2PACX-1vQ31YFdnRDM3EeKoU-Cpzc58bC1ZlfJqWpaLNiUY51EV5PZigMMtdREZX_dXtKEE7AGeafdXZWgqUWS/pub?output=csv",
      {
        download: true,
        header: true,
        complete: (results) => {
          const data: SheetData = (results?.data as any) || [];

          setSheetData(data);

          let fragrances_names: string[] = Array.from(
            new Set(data.map((row) => row["Name of Fragrance"]))
          );

          let fragrances: Fragrance[] = fragrances_names.map(
            (fragrance_name) => ({
              name: fragrance_name,
              index: data.findIndex(
                (row) => row["Name of Fragrance"] === fragrance_name
              ),
            })
          );

          setFragrances(fragrances);
        },
      }
    );
  }, []);

  const selectedRow = sheetData.filter(
    (row) =>
      row["Name of Fragrance"] === formik.values.fragrance &&
      row["Product Q.ty %"] === formik.values.quality
  )?.[0];

  const printDocument = () => {
    const input = document.getElementById("pdf-preview")!;
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF({
        format: [600, 600],
      });
      pdf.addImage(
        imgData,
        "JPEG",
        0,
        0,
        600,
        600,
        undefined,
        undefined,
        undefined
      );
      pdf.save(`${formik.values.productName} - label.pdf`);
    });
  };

  return (
    <>
      <header className="container">
        <hgroup>
          <h1>
            <img
              src="/logo.jpg"
              style={{
                height: "48px",
                borderRadius: "8px",
                marginRight: "12px",
              }}
              alt=""
            />
            {t("title")}
          </h1>
          <h2>
            <a href="#learn-more">{t("subTitle")}</a>
          </h2>
        </hgroup>
        <LanguageSelect />
      </header>
      <main className="container">
        <Form
          sheetData={sheetData}
          fragrances={fragrances}
          formik={formik}
          openModal={() => setModalOpen(true)}
        />
        <dialog open={modalOpen}>
          <article>
            <header>
              <a
                onClick={(event) => {
                  event.preventDefault();
                  setModalOpen(false);
                }}
                href="#close"
                aria-label="Close"
                className="close"
              >
                {""}
              </a>
              {t("PDFPreview")}
            </header>
            <p>
              <div className="modal-body">
                <PDFPreview
                  prodcutName={formik.values.productName}
                  contains={
                    selectedRow?.[`Contains ${formik.values.language}`] ||
                    (formik.values.fragrance && formik.values.quality
                      ? t("missingError")
                      : t("pleaseChooseTheFragranceNameAndProduct"))
                  }
                  warning={
                    selectedRow?.[`Warning ${formik.values.language}`] ||
                    (formik.values.fragrance && formik.values.quality
                      ? t("missingError")
                      : t("pleaseChooseTheFragranceNameAndProduct"))
                  }
                  michelealbes={["GHS07", "GHS09", "GHS08", "GHS05"].filter(
                    (michelealbe) => selectedRow?.[michelealbe] === "YES"
                  )}
                  batchNumber={formik.values.batchNumber}
                  ufi={formik.values.ufi}
                  businessName={formik.values.businessName}
                  businessAddress={formik.values.businessAddress}
                  businessTelephone={formik.values.businessTelephone}
                  background={formik.values.background}
                  textColor={formik.values.textColor}
                  shape={formik.values.shape}
                  mass={formik.values.mass}
                  unit={formik.values.unit}
                  textOffset={formik.values.textOffset}
                  addressOffset={formik.values.addressOffset}
                  fontSize={formik.values.fontSize}
                  showBorder={formik.values.showBorder}
                  pictograms={formik.values.pictograms}
                  language={formik.values.language}
                />
                <div className="label">
                  <span>{t("textControl")}</span>
                  <span className="buttons items-center">
                    <button
                      className="btn-sm"
                      onClick={() =>
                        formik.setFieldValue(
                          "textOffset",
                          Number(formik.values.textOffset) + 1
                        )
                      }
                    >
                      <Arrow />
                    </button>
                    <input
                      className="btn-sm"
                      {...formik.getFieldProps("textOffset")}
                    />
                    <button
                      className="btn-sm"
                      onClick={() =>
                        formik.setFieldValue(
                          "textOffset",
                          Number(formik.values.textOffset) - 1
                        )
                      }
                    >
                      <Arrow down />
                    </button>
                  </span>
                </div>
                <div className="label">
                  <span>{t("addressControl")}</span>
                  <span className="buttons items-center">
                    <button
                      className="btn-sm"
                      onClick={() =>
                        formik.setFieldValue(
                          "addressOffset",
                          Number(formik.values.addressOffset) + 1
                        )
                      }
                    >
                      <Arrow />
                    </button>
                    <input
                      className="btn-sm"
                      {...formik.getFieldProps("addressOffset")}
                    />
                    <button className="btn-sm">
                      <Arrow
                        down
                        onClick={() =>
                          formik.setFieldValue(
                            "addressOffset",
                            Number(formik.values.addressOffset) - 1
                          )
                        }
                      />
                    </button>
                  </span>
                </div>
                <label className="label">
                  <span>{t("fontSize")}</span>
                  <select {...formik.getFieldProps("fontSize")}>
                    {[10, 11, 12, 13].map((size) => (
                      <option key={size} value={size + "pt"}>
                        {size}pt
                      </option>
                    ))}
                  </select>
                </label>
                <label className="label">
                  <span style={{ marginBottom: "-1rem" }}>
                    {t("borderControl")}
                  </span>
                  <input
                    type="checkbox"
                    role="switch"
                    checked={formik.values.showBorder}
                    onChange={(event) =>
                      formik.setFieldValue("showBorder", event.target.checked)
                    }
                  />
                </label>
                <button onClick={printDocument}>{t("downloadNow")}!</button>
              </div>
            </p>
          </article>
        </dialog>
        <button
          onClick={themeSwitcher.toggleTheme.bind(themeSwitcher)}
          className="contrast theme-switcher"
        />
      </main>
    </>
  );
}

export default App;
