const translations = {
  en: {
    translation: {
      title: "CLP Label Generator",
      subTitle: "Click here for more information about CLP labels",
      fragrance: "Hobbyland Fragrance",
      product: "Product",
      language: "Label Language",
      mass: "Mass / Volume",
      shape: "Label Shape",
      background: "Background color",
      text: "Text color",
      black: "Black",
      white: "White",
      productName: "Product Name",
      businessName: "Business Name",
      businessAddress: "Business Address",
      businessTelephone: "Business Telephone",
      UFI: "UFI",
      batchNumber: "Batch Number",
      EN15494: "EN 15494: (Choose All Applicable) Max 5",
      refresh: "Refresh",
      pleaseChooseTheFragranceNameAndProduct:
        "Please choose the fragrance name and product",
      warning: "Warning",
      textControl: "Text Up/Down",
      addressControl: "Address Up / Down",
      fontSize: "Font Size",
      borderControl: "Show / Hide Border",
      units: "Units",
      milliliters: "Milliliters",
      grams: "Grams",
      notSelected: "Not Selected",
      english: "English",
      italian: "Italian",
      french: "French",
      dutch: "Dutch",
      spanish: "Spanish",
      productPlaceholder: "Product Name",
      businessNamePlaceholder: "Your Business Name",
      addressPlaceholder: "Address Line 1, Town/City, County and Postcode.",
      preview: "Preview",
      PDFPreview: "PDF Preview",
      net: "Net",
      missingError: "Sorry we don't have this data",
      downloadNow: "Download Now",
    },
  },
  it: {
    translation: {
      title: "Generatore di Etichette CLP",
      subTitle:
        "Clicca qui per maggiori informazioni relative alle etichette CLP",
      fragrance: "Fragranza Hobbyland",
      product: "Prodotto",
      language: "Lingua etichetta",
      mass: "Peso / Volume",
      shape: "Forma etichetta",
      background: "Colore di fondo",
      text: "Colore testo",
      black: "Nero",
      white: "Bianco",
      productName: "Nome del prodotto",
      businessName: "Nome dell'azienda",
      businessAddress: "Indrizzo dell'azienda",
      businessTelephone: "Telefono dell'azienda",
      UFI: "UFI",
      batchNumber: "Numero di lotto",
      EN15494: "EN 15494: (scegliere tutte le applicabili) Max 5",
      refresh: "Ricaricare",
      pleaseChooseTheFragranceNameAndProduct:
        "Prego selezionare il nome della fragranza e del prodotto",
      warning: "Avvertenze",
      textControl: "Testo Su / Giù",
      addressControl: "Indirizzo Su / Giù",
      fontSize: "Dimensioni Font",
      borderControl: "Mostra / Nascondi Bordo",
      units: "Unità",
      milliliters: "Millilitri",
      grams: "Grammi",
      notSelected: "Non selezionato",
      english: "Inglese",
      italian: "Italiano",
      french: "francese",
      dutch: "Olandese",
      spanish: "spagnolo",
      productPlaceholder: "nome del prodotto",
      businessNamePlaceholder: "Il tuo nome commerciale",
      addressPlaceholder:
        "Linea di indirizzo 1, città/città, contea e codice postale.",
      preview: "Anteprima",
      PDFPreview: "Anteprima PDF",
      net: "Netto",
      missingError: "Scusa non abbiamo questi dati",
      downloadNow: "Scarica ora",
    },
  },
  fr: {
    translation: {
      title: "Générateur d'étiquettes CLP",
      subTitle: "Cliquez ici pour plus d'informations sur les étiquettes CLP",
      fragrance: "Fragrance Hobbyland",
      product: "Produit",
      language: "Langue de l'étiquette",
      mass: "Poids / Volume",
      shape: "Forme de l'étiquette",
      background: "Couleur de fond",
      text: "Couleur du texte",
      black: "Noir",
      white: "Blanc",
      productName: "Nom du produit",
      businessName: "Nom de l'entreprise",
      businessAddress: "Adresse d'affaires",
      businessTelephone: "Téléphone d'affaires",
      UFI: "UFI",
      batchNumber: "Numéro de lot",
      EN15494: "EN 15494 : (Sélectionner tout applicable) Max 5",
      refresh: "Rafraîchir",
      pleaseChooseTheFragranceNameAndProduct:
        "Veuillez choisir le nom du parfum et le produit",
      warning: "Avertissement",
      textControl: "Texte haut/bas",
      addressControl: "Adresse Haut / Bas",
      fontSize: "Taille de police",
      borderControl: "Mostrar / ocultar borde",
      units: "Unités",
      milliliters: "Millilitres",
      grams: "Grammes",
      notSelected: "Non séléctionné",
      english: "Anglais",
      italian: "italien",
      french: "Français",
      dutch: "Néerlandais",
      spanish: "Espagnol",
      productPlaceholder: "Nom de produit",
      businessNamePlaceholder: "Nom de votre entreprise",
      addressPlaceholder:
        "Ligne d'adresse 1, ville / ville, comté et code postal.",
      preview: "Aperçu",
      PDFPreview: "Aperçu PDF",
      net: "Filet",
      missingError: "Désolé, nous n'avons pas ces données",
      downloadNow: "Télécharger maintenant",
    },
  },
  du: {
    translation: {
      title: "CLP-Label-Generator",
      subTitle:
        "Klicken Sie hier, um weitere Informationen zu CLP-Etiketten zu erhalten",
      fragrance: "Hobbyland-Duft",
      product: "Produkt",
      language: "Labelsprache",
      mass: "Gewicht / Volumen",
      shape: "Etikettenform",
      background: "Hintergrundfarbe",
      text: "Textfarbe",
      black: "Schwarz",
      white: "Weiss",
      productName: "Produktname",
      businessName: "Firmenname",
      businessAddress: "Geschäftsadresse",
      businessTelephone: "Geschäftstelefon",
      UFI: "UFI",
      batchNumber: "Chargennummer",
      EN15494: "EN 15494: (Alles Zutreffende auswählen) Max. 5",
      refresh: "Aktualisierung",
      pleaseChooseTheFragranceNameAndProduct:
        "VBitte wählen Sie den Duftnamen und das Produkt aus",
      warning: "Warnung",
      textControl: "Text hoch/runter",
      addressControl: "Adresse nach oben / unten",
      fontSize: "Schriftgröße",
      borderControl: "Rahmen ein-/ausblenden",
      units: "Einheiten",
      milliliters: "Milliliter",
      grams: "Gramm",
      notSelected: "Nicht ausgewählt",
      english: "Englisch",
      italian: "Italienisch",
      french: "Französisch",
      dutch: "Niederländisch",
      spanish: "Spanisch",
      productPlaceholder: "Produktname",
      businessNamePlaceholder: "Ihr Firmenname",
      addressPlaceholder:
        "Adresslinie 1, Stadt/Stadt, Grafschaft und Postleitzahl.",
      preview: "Vorschau",
      PDFPreview: "PDF -Vorschau",
      net: "Netz",
      missingError: "Entschuldigung, wir haben diese Daten nicht",
      downloadNow: "Jetzt downloaden",
    },
  },
  es: {
    translation: {
      title: "Generador de etiquetas CLP",
      subTitle:
        "Haga clic aquí para obtener más información sobre las etiquetas CLP",
      fragrance: "Fragrancia Hobbyland",
      product: "Producto",
      language: "Idioma de la etiqueta",
      mass: "Peso / Volumen",
      shape: "Forma de la etiqueta",
      background: "Color de fondo",
      text: "Color de texto",
      black: "Negro",
      white: "Blanco",
      productName: "Nombre del producto",
      businessName: "Nombre del negocio",
      businessAddress: "Dirección del negocio",
      businessTelephone: "Telefono del negocio",
      UFI: "UFI",
      batchNumber: "Número de lote",
      EN15494: "EN 15494: (Elija todo lo que corresponda) Máx. 5",
      refresh: "Actualizar",
      pleaseChooseTheFragranceNameAndProduct:
        "VElige el nombre de la fragancia y el producto.",
      warning: "Advertencia",
      textControl: "Texto arriba/abajo",
      addressControl: "Dirección arriba/abajo",
      fontSize: "Tamaño de fuente",
      borderControl: "Mostrar/ocultar borde",
      units: "Unidades",
      milliliters: "Mililitros",
      grams: "Gramos",
      notSelected: "No seleccionado",
      english: "Inglés",
      italian: "italiano",
      french: "Francés",
      dutch: "Holandés",
      spanish: "Español",
      productPlaceholder: "nombre del producto",
      businessNamePlaceholder: "Tu nombre comercial",
      addressPlaceholder:
        "Dirección Línea 1, ciudad/ciudad, condado y código postal.",
      preview: "Avance",
      PDFPreview: "Vista previa de PDF",
      net: "Neto",
      missingError: "Lo siento, no tenemos estos datos",
      downloadNow: "Descargar ahora",
    },
  },
};

export default translations;
