import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import flags from "./flags";

const languageFlags: { [x: string]: any } = {
  en: flags.GB,
  it: flags.IT,
  fr: flags.FR,
  du: flags.GR,
  es: flags.ES,
};

const LanguageSelect = () => {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ?? "it"
  );

  const { i18n } = useTranslation("translation");

  useEffect(() => {
    i18n.changeLanguage(language);
    localStorage.setItem("language", language);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language]);

  return (
    <div className="language-select">
      <button className="head">{languageFlags[language]}</button>
      <div className="body">
        {Object.keys(languageFlags).map((language_option) => (
          <div
            className={`option ${
              language === language_option ? "selected" : ""
            }`}
            key={language_option}
            onClick={() => setLanguage(language_option)}
          >
            {languageFlags[language_option]}
          </div>
        ))}
      </div>
    </div>
  );
};

export default LanguageSelect;
