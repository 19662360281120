import React, { CSSProperties } from "react";
import translations from "../i18n/translations";

export type Shape = "circle" | "square";

type Props = {
  prodcutName: string;
  contains: string;
  warning: string;
  michelealbes: string[];
  batchNumber: string;
  ufi: string;
  businessName: string;
  businessAddress: string;
  businessTelephone: string;
  background: string;
  textColor: string;
  shape: Shape;
  mass: number;
  unit: string;
  textOffset: number;
  addressOffset: number;
  fontSize: string;
  showBorder: boolean;
  pictograms: number[];
  language: string;
};

const abbreviations: any = {
  it: "ITA",
  en: "ENG",
  fr: "FRA",
  du: "DEU",
  es: "ESP",
};

const PDFPreview: React.FC<Props> = ({
  prodcutName,
  contains,
  background,
  textColor,
  fontSize,
  showBorder,
  warning,
  michelealbes,
  businessName,
  businessAddress,
  businessTelephone,
  mass,
  unit,
  batchNumber,
  ufi,
  textOffset,
  addressOffset,
  shape,
  pictograms,
  language,
}) => {
  const [chosenLanguage] = Object.entries(abbreviations).filter(
    ([, value]) => value === language
  )[0];

  const styles: {
    [x: string]: CSSProperties;
  } = {
    page: {
      backgroundColor: background,
    },
    outerWrapper: {
      backgroundColor: background,
      padding: "16px",
      fontFamily:
        " -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
      color: textColor,
      fontSize,
      width: "600px",
      height: "600px",
      display: "flex",
    },
    wrapper: {
      position: "relative",
      borderRadius: shape === "circle" ? "9999px" : 0,
      display: "flex",
      flexDirection: "column",
      width: "100%",
      padding: "16px 12px",
      border: showBorder ? `2px dashed ${textColor}` : "none",
    },
    header: {
      width: "100%",
      textAlign: "center",
      display: "flex",
      flexDirection: "column",
    },
    heading: {
      color: textColor,
      fontSize: "20px",
      marginBottom: "8px",
      lineHeight: "1.2",
      fontWeight: "bold",
    },
    text: {
      textAlign: "center",
      transform: `translateY(${textOffset * -1}px)`,
    },
    warning: {
      textAlign: "center",
      marginInline: "auto",
      padding: "16px 0",
    },
    warningHeading: {
      color: textColor,
      fontSize,
      display: shape === "circle" ? undefined : "flex",
      justifyContent: "center",
    },
    warningImage: {
      width: "130px",
      height: "130px",
      marginTop: pictograms.length && shape === "square" ? "60px" : "10px",
      flexGrow: 0,
    },
    lowerContent: {
      marginTop: "auto",
      display: "flex",
      justifyContent: "space-between",
      height: "130px",
      position: "relative",
      transform: `translateY(${addressOffset * -1}px)`,
    },
    address: {
      position: "absolute",
      top: 0,
      left: "50%",
      width: "100%",
      maxWidth: "380px",
      transform: "translateX(-50%)",
      textAlign: "center",
    },
    upperDetails: {
      color: textColor,
      fontSize,
      fontWeight: "bold",
      display: "flex",
      gap: "60px",
      justifyContent: "center",
    },
    lowerDetails: {
      color: textColor,
      fontSize,
      position: "absolute",
      bottom: 8,
      left: 8,
      right: 8,
      display: "flex",
      justifyContent: "space-between",
    },
    textLine: {
      color: textColor,
      fontSize,
      display: "flex",
      justifyContent: "center",
      textAlign: "center",
      marginBottom: shape === "circle" ? "2px" : "8px",
    },
    pictograms: {
      display: "flex",
      justifyContent: "center",
      height: pictograms.length ? "70px" : 0,
    },
    strong: {
      color: textColor,
      fontSize,
      fontWeight: "bold",
    },
  };

  return (
    <div id="pdf-preview">
      <div style={styles.page}>
        <div style={styles.outerWrapper}>
          <div style={styles.wrapper}>
            <div style={styles.header}>
              <svg
                viewBox="0 0 600 600"
                fill={textColor}
                style={{
                  position: "absolute",
                  opacity: shape === "circle" ? 1 : 0,
                  inset: 0,
                }}
              >
                <path
                  fill="transparent"
                  id="curve"
                  d="M134 98c47.375-47.541 182.299-114.1 343 0"
                />
                <text
                  width="600"
                  style={{
                    textAlign: "center",
                  }}
                >
                  <textPath
                    xlinkHref="#curve"
                    style={{
                      textAlign: "center",
                      whiteSpace: "pre",
                      ...styles.heading,
                    }}
                  >
                    {" ".repeat(37 - prodcutName.length) + prodcutName}
                  </textPath>
                </text>
              </svg>

              <h3
                style={{
                  ...styles.heading,
                  opacity: shape === "square" ? 1 : 0,
                }}
              >
                {prodcutName}
              </h3>
            </div>
            <div style={styles.text}>
              <p
                style={{
                  color: textColor,
                  fontSize,
                  opacity: 0.75,
                  marginBottom: "16px",
                  textAlign: "center",
                  marginInline: "auto",
                  maxWidth: shape === "circle" ? "300px" : "auto",
                }}
              >
                ({contains})
              </p>
              <div
                style={{
                  ...styles.warning,
                  whiteSpace: "nowrap",
                  overflow: "auto",
                }}
              >
                {shape === "circle" && (
                  <WarningImage
                    michelealbe={michelealbes[0]}
                    style={{
                      ...styles.warningImage,
                      float: "left",
                      display: "inline-block",
                      shapeOutside:
                        "polygon(50% 16px, 97.69% 53.57%, 50% 100%, 3px 54.29%)",
                    }}
                  />
                )}
                {shape === "circle" && (
                  <WarningImage
                    michelealbe={michelealbes[1]}
                    style={{
                      ...styles.warningImage,
                      float: "right",
                      display: "inline-block",
                      shapeOutside:
                        "polygon(50% 16px, 97.69% 53.57%, 50% 100%, 3px 54.29%)",
                    }}
                  />
                )}
                <p style={{ display: "inline", whiteSpace: "normal" }}>
                  <span
                    style={{
                      ...styles.strong,
                      ...styles.warningHeading,
                    }}
                  >
                    {!michelealbes.length ? (
                      <img
                        src={"/danger.png"}
                        alt=""
                        width="90"
                        style={{
                          display: "block",
                          marginInline: "auto",
                          marginBottom: "8px",
                        }}
                      />
                    ) : (
                      // @ts-ignore
                      `${translations[chosenLanguage].translation.warning}. `
                    )}
                  </span>
                  <p style={{ color: textColor, fontSize }}>{warning}</p>
                </p>
              </div>
            </div>
            <div style={styles.lowerContent}>
              {shape === "square" && (
                <WarningImage
                  michelealbe={michelealbes[0]}
                  style={styles.warningImage}
                />
              )}
              <div style={styles.address}>
                <div style={styles.pictograms}>
                  {pictograms.map((pictogramIndex) => (
                    <PictogramImage
                      key={`pictogram-${pictogramIndex}`}
                      index={pictogramIndex}
                      textColor={textColor}
                    />
                  ))}
                </div>
                {shape === "circle" && (
                  <div style={styles.upperDetails}>
                    <strong>{batchNumber}</strong>
                    <strong>{ufi}</strong>
                  </div>
                )}
                <p style={styles.textLine}>
                  {businessName},{businessAddress}
                </p>
                <p style={styles.textLine}>
                  {chosenLanguage === "fr" ? "Tél" : "Tel"}: {businessTelephone}
                </p>
                <p style={styles.strong}>
                  ~{mass}
                  {/* @ts-ignore */}
                  {unit} {translations[chosenLanguage].translation.net}
                </p>
              </div>
              {shape === "square" && (
                <WarningImage
                  michelealbe={michelealbes[1]}
                  style={styles.warningImage}
                />
              )}
            </div>
            {shape === "square" && (
              <div style={styles.lowerDetails}>
                <strong>{batchNumber}</strong>
                <strong>{ufi}</strong>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const WarningImage: React.FC<{
  michelealbe: string | null;
  style: any;
}> = ({ michelealbe, style }) => {
  if (!michelealbe) return null;
  return <img style={style} src={`/michelealbes/${michelealbe}.png`} alt="" />;
};

const PictogramImage: React.FC<{
  index: number;
  textColor: string;
}> = ({ index, textColor }) => {
  const styles = {
    pictogram: {
      width: "70px",
      height: "70px",
      marginRight: "8px",
    },
  };
  return (
    <img
      style={styles.pictogram}
      src={`/pictogram/${index}_${
        textColor === "#000000" ? "black" : "white"
      }.png`}
      alt=""
    />
  );
};

export default PDFPreview;
